import React, { ElementType, LegacyRef, PropsWithChildren } from "react";
import { ButtonVariantsValues, ButtonVariants } from "./Button.types";
import { styles } from "./Button.style";

interface IProps {
  onClick?: React.MouseEventHandler;
  onKeyUp?: React.KeyboardEventHandler<HTMLAnchorElement>;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  variant?: ButtonVariants;
  isMultiline?: boolean;
  className?: string;
  testSelector?: string;
  href?: string;
  element?: ElementType;
  value?: string;
  form?: string;
  openInNewTab?: boolean;
}

export const Button: React.FC<PropsWithChildren<IProps>> = React.forwardRef(
  (
    {
      onClick,
      onKeyUp,
      type = "button",
      disabled,
      variant = ButtonVariants.Standard,
      isMultiline = false,
      className,
      testSelector,
      href,
      element,
      value,
      children,
      form,
      openInNewTab = false,
    },
    ref: LegacyRef<HTMLAnchorElement>
  ) => {
    if (href) {
      const target = openInNewTab ? "_blank" : "_self";
      return (
        <a
          css={[styles.button(ButtonVariantsValues[variant], isMultiline), styles.a()]}
          className={className}
          onClick={onClick}
          onKeyUp={onKeyUp}
          data-testid={testSelector}
          href={href}
          ref={ref}
          target={target}
        >
          {children}
        </a>
      );
    }

    const Component = element || "button";

    return (
      <Component
        css={styles.button(ButtonVariantsValues[variant], isMultiline)}
        className={className}
        type={type}
        disabled={disabled}
        onClick={onClick}
        onKeyUp={onKeyUp}
        data-testid={testSelector}
        value={value}
        ref={ref}
        form={form}
      >
        {children}
      </Component>
    );
  }
);

Button.displayName = "Button";
