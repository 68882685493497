import { css, keyframes } from "@emotion/react";
import { colors } from "../../../core/styles";

const showLine = keyframes`
  0% {
    top:-20px;
    left:-20px;
  }
   100% {
    top: 1px;
    left: 3px;
   }
 }`;
const hideLine = keyframes`
  from {
    top: 1px;
    left: 3px;
  }
  to {
    top:-20px;
    left:-20px;
 }`;

const clickAnimation = keyframes`
   0% {
    transform: scale3d(0.3,0.3,1);
  }
  25%,50% {
    opacity:1;
  }
  100% {
    opacity :0;
    transform: scale3d(1.2,1.2,1);
  }
 }`;

export const styles = {
  icon: (color: string) =>
    css({
      transition: "fill .2s ease-in-out",
      position: "relative",
      height: "24px",
      width: "24px",
      overflow: "hidden",
      zIndex: 1,
      path: {
        fill: color || colors.headlineGrey.hex,
      },
      "&::before": {
        content: "''",
        position: "absolute",
        top: "-20px",
        left: "-20px",
        borderRadius: "1px",
        height: "2px",
        backgroundColor: color || colors.headlineGrey.hex,
        width: "110%",
        transform: "rotate(45deg)",
        transformOrigin: "top left",
      },
    }),
  animationOn: (showPassword) =>
    css({
      "&::before": {
        animation: showPassword ? `${showLine} .2s ease-out forwards` : `${hideLine} .2s ease-in forwards`,
      },
    }),

  iconContainer: () =>
    css({
      height: "24px",
      width: "24px",
      position: "relative",
      "&[data-animate='1']::after": {
        animation: `${clickAnimation} .5s forwards`,
      },
      "&::after": {
        content: "''",
        backgroundColor: `rgba(${colors.disabledGrey.rgba},0.3)`,
        position: "absolute",
        top: "50%",
        left: "50%",
        margin: "-20px 0 0 -20px",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        opacity: 0,
      },
    }),
};
