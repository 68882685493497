import { css, keyframes } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, spacing } from "../../core/styles";

const fadeInAnimation = keyframes`
  0%  { opacity: 0; transform: translateY(-8px); };
  75% { opacity: 1 };
  100% { opacity: 1 ; transform: translateY(0); }`;

const fadeOutAnimation = keyframes`
  0%  { opacity: 1; transform: translateY(0) };
  25% { opacity: 0 };
  100% { opacity: 0; transform: translateY(-8px); }`;

export const styles = {
  form: () =>
    css({
      position: "relative",
      width: "100%",
      minHeight: "78px",
      "&:focus-within": {
        fieldset: {
          border: `1px solid ${colors.linkBlue.hex}`,
        },
        label: {
          color: colors.linkBlue.hex,
        },
        input: {
          color: colors.headlineGrey.hex,
          outline: "none",
        },
        "& > div": {
          color: colors.linkBlue.hex,
        },
      },
    }),

  withError: () =>
    css({
      "& label, & > div, & input": {
        color: colors.red.hex,
      },
      "& fieldset": {
        borderColor: colors.red.hex,
      },
      "& > div ul": {
        color: "inherit",
        fontSize: "inherit",
        listStyle: "initial",
        paddingInlineStart: spacing.baseUnitMobile2,
        lineHeight: "inherit",
      },
    }),

  disabled: () =>
    css({
      "& label": {
        color: colors.lightGrey.hex,
      },
      "& input": {
        color: colors.lightGrey.hex,
      },
      "& fieldset": {
        backgroundColor: colors.backgroundGrey.hex,
      },
    }),

  inputContainer: () =>
    css({
      position: "relative",
      height: "100%",
      width: "100%",
      gridArea: "input",
    }),

  labelText: (frontIcon: boolean) =>
    css({
      transition: "color .2s ease-in-out",
      position: "absolute",
      top: "8px",
      left: frontIcon ? 0 : spacing.baseUnitMobile2,
      color: colors.stdTextGrey.hex,
      lineHeight: 1,
      height: "14px",
      fontSize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
    }),

  input: (withLabel: boolean, endIcon: boolean, frontIcon: boolean) =>
    css({
      transition: "color .2s ease-in-out",
      width: "100%",
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      color: colors.headlineGrey.hex,
      margin: 0,
      border: "none",
      paddingTop: withLabel ? "22px" : "15px",
      paddingBottom: withLabel ? "8px" : "15px",
      paddingRight: endIcon ? 0 : spacing.baseUnitMobile2,
      paddingLeft: frontIcon ? 0 : spacing.baseUnitMobile2,
      borderTopRightRadius: endIcon ? 0 : "4px",
      borderBottomRightRadius: endIcon ? 0 : "4px",
      borderTopLeftRadius: frontIcon ? 0 : "4px",
      borderBottomLeftRadius: frontIcon ? 0 : "4px",
      "&:focus": {
        outline: 0,
      },
      "&::placeholder": {
        color: colors.lightGrey.hex,
      },
    }),

  content: () =>
    css({
      display: "grid",
      gridTemplateAreas: `
      'frontIcon input endIcon'
      `,
      gridTemplateColumns: "max-content auto max-content",
      borderRadius: "4px",
      backgroundColor: colors.white.hex,
      border: `1px solid ${colors.lightGrey.hex}`,
      height: "54px",
      transition: "border-color .2s ease-in-out",
      boxSizing: "content-box",
    }),
  info: (show: boolean) =>
    css({
      transition: "color .2s ease-in-out",
      color: colors.stdTextGrey.hex,
      lineHeight: 1.25,
      fontSize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      width: "100%",
      padding: `4px ${spacing.baseUnitMobile2} 4px`,
      animation: show ? `.2s forwards ${fadeInAnimation}` : `.2s forwards ${fadeOutAnimation}`,
    }),
  endIcon: () =>
    css({
      gridArea: "endIcon",
      display: "flex",
      alignSelf: "center",
      border: "none",
      backgroundColor: "transparent",
      margin: 0,
    }),
  frontIcon: () =>
    css({
      gridArea: "frontIcon",
      display: "flex",
      alignSelf: "center",
      border: "none",
      backgroundColor: "transparent",
      margin: 0,
    }),
  passwordButton: () =>
    css({
      margin: `0 ${spacing.baseUnitMobile1}`,
    }),
};
