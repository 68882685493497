import { colors } from "../../../core/styles";
import { styles } from "./PasswordIcon.styles";
import { useState } from "react";
import { Icon, Icons } from "../../Icon";

export const PasswordIcon = ({ hasError = false, disabled = false, showPassword = false }) => {
  const getIconColor = () => {
    if (disabled) {
      return colors.lightGrey.hex;
    }
    if (hasError) {
      return colors.red.hex;
    }
    return colors.headlineGrey.hex;
  };
  const iconColor = getIconColor();

  const [animation, setAnimation] = useState<number | undefined>();
  return (
    <div
      id="passwordIcon"
      css={styles.iconContainer}
      onClick={() => setAnimation(1)}
      onAnimationEnd={() => setAnimation(0)}
      data-animate={animation}
    >
      <Icon
        css={[styles.icon(iconColor), typeof animation === "number" && styles.animationOn(showPassword)]}
        icon={Icons.eyeIcon}
      ></Icon>
    </div>
  );
};
