import { css } from "@emotion/react";
import { ButtonVariant } from "./Button.types";

import { fontSizes, spacing } from "../../core/styles";

export const styles = {
  button: (variant: ButtonVariant, isMultiline: boolean) => {
    const calculatePadding = () => (isMultiline ? `3px ${spacing.baseUnitMobile3}` : `12px ${spacing.baseUnitMobile3}`);
    return css({
      fontFamily: "inherit",
      borderRadius: "22px",
      fontWeight: "bold",
      fontSize: variant.fontSize || fontSizes.body.cat,
      padding: variant.padding || calculatePadding(),
      letterSpacing: variant.letterSpacing || "1px",
      lineHeight: variant.lineHeight,
      transition: "all 0.3s ease",
      position: "relative",
      pointerEvents: "initial",
      textAlign: "center",
      color: variant.text,
      background: variant.background,
      border: variant.border ? `1px solid ${variant.border}` : "none",
      "&:hover": {
        background: variant.backgroundHover,
        boxShadow: variant.boxShadow ? variant.boxShadow : "none",
        cursor: "pointer",
      },
      "&:focus": {
        outline: "none",
        background: variant.backgroundFocused,
        boxShadow: variant.boxShadow ? variant.boxShadow : "none",
      },
      "&:disabled": {
        background: variant.backgroundDisabled,
        border: variant.borderDisabled ? `1px solid ${variant.borderDisabled}` : "none",
        color: variant.textDisabled,
        boxShadow: "none",
        cursor: "default",
      },
    });
  },
  a: () =>
    css({
      display: "inline-block",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    }),
};
