import React, { LegacyRef, MouseEventHandler, forwardRef, useState } from "react";
import { styles } from "./Input.styles";
import { PasswordIcon } from "./icons/PasswordIcon";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  labelText?: string;
  message?: string;
  hasError?: boolean;
  customEndIcon?: React.ReactNode;
  customFrontIcon?: React.ReactNode;
  ariaLabelShowPasswordButton?: string;
  testId?: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      labelText,
      id,
      message,
      hasError,
      disabled,
      type,
      testId,
      customEndIcon,
      customFrontIcon,
      ariaLabelShowPasswordButton = "Show password",
      ...rest
    },
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const isPassword = type && type === "password";
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword: MouseEventHandler = (e) => {
      e.preventDefault();
      if (!disabled) {
        setShowPassword(!showPassword);
      }
    };

    const getInputType = () => {
      if (type === "number") {
        return "number";
      }
      return isPassword && !showPassword ? "password" : "text";
    };

    return (
      <div css={[styles.form, hasError && styles.withError, disabled && styles.disabled]} data-testid={testId}>
        <fieldset css={styles.content}>
          {customFrontIcon && <div css={styles.frontIcon}>{customFrontIcon}</div>}
          <div css={styles.inputContainer}>
            {labelText && (
              <label css={styles.labelText(!!customFrontIcon)} htmlFor={id}>
                {labelText}
              </label>
            )}
            <input
              aria-label={labelText}
              css={styles.input(!!labelText, !!customEndIcon || isPassword, !!customFrontIcon)}
              type={getInputType()}
              id={id}
              disabled={disabled}
              ref={ref}
              {...rest}
            />
          </div>
          {isPassword && (
            <button
              aria-label={ariaLabelShowPasswordButton}
              type="button"
              css={[styles.endIcon, styles.passwordButton]}
              onClick={togglePassword}
            >
              <PasswordIcon hasError={hasError} disabled={disabled} showPassword={showPassword} />
            </button>
          )}
          {customEndIcon && <div css={styles.endIcon}>{customEndIcon}</div>}
        </fieldset>
        {message && <div css={styles.info(!!message)} dangerouslySetInnerHTML={{ __html: message }} />}
      </div>
    );
  }
);
Input.displayName = "Input";
