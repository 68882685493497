import { colors, fontSizes, letterSpacings, lineHeights } from "../../core/styles";

type ButtonVariantMap = Record<ButtonVariants, ButtonVariant>;
export enum ButtonVariants {
  Standard = "Standard",
  Outlined = "Outlined",
  TextDecorated = "TextDecorated",
  Text = "Text",
}

export const ButtonVariantsValues: ButtonVariantMap = {
  Standard: {
    value: "standard",
    text: colors.white.hex,
    textDisabled: colors.white.hex,
    background: colors.linkBlue.hex,
    backgroundHover: colors.linkBlue.hex,
    backgroundDisabled: colors.lightGrey.hex,
    backgroundFocused: colors.paleBlue.hex,
    boxShadow: "rgba(0,0,0,0.4) 1px 2px 6px 0px",
    lineHeight: lineHeights.body.cat,
  },
  Outlined: {
    value: "outlined",
    text: colors.linkBlue.hex,
    textDisabled: colors.lightGrey.hex,
    border: colors.linkBlue.hex,
    borderDisabled: colors.lightGrey.hex,
    background: colors.transparent.hex,
    backgroundHover: colors.backgroundGrey.hex,
    backgroundDisabled: colors.transparent.hex,
    backgroundFocused: colors.paleBlueLight.hex,
    lineHeight: lineHeights.body.cat,
  },
  TextDecorated: {
    value: "textDecorated",
    text: colors.linkBlue.hex,
    textDisabled: colors.lightGrey.hex,
    background: colors.transparent.hex,
    backgroundHover: colors.backgroundGrey.hex,
    backgroundDisabled: colors.transparent.hex,
    backgroundFocused: colors.paleBlueLight.hex,
    lineHeight: lineHeights.body.cat,
  },
  Text: {
    value: "text",
    text: colors.linkBlue.hex,
    textDisabled: colors.lightGrey.hex,
    background: colors.transparent.hex,
    backgroundHover: colors.transparent.hex,
    backgroundDisabled: colors.transparent.hex,
    backgroundFocused: colors.transparent.hex,
    fontSize: fontSizes.body.horse,
    lineHeight: lineHeights.body.horse,
    letterSpacing: letterSpacings.body.horse,
    padding: "0",
  },
};

export type ButtonVariant = {
  value: string;
  text: string;
  textDisabled: string;
  background: string;
  backgroundHover: string;
  backgroundDisabled: string;
  backgroundFocused: string;
  boxShadow?: string;
  border?: string;
  borderDisabled?: string;
  lineHeight: string;
  fontSize?: string;
  letterSpacing?: string;
  padding?: string;
};
